<template>
  <b-card class="shop-agents">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="agentsGrid"
          :button-label="$t('Add Agents')"
          :columns="agentsColumn"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="'/shop/agents/all'"
          @add-new-info="showAddStoreModal"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showStoreEdit"
      :no-close-on-backdrop="true"
      :title="editStoreInfo && editStoreInfo.id ? 'Edit Agent' : 'Add Agent'"
      size="lg"
      ok-only
      hide-footer
      :ok-title="$t('Update Agent')"
      @hidden="hideStoreAgents"
      @ok="updateAgents"
    >
      <b-row v-if="editStoreInfo">
        <b-col cols="12" md="6">
          <b-form-group :label="$t('First Name')">
            <b-form-input v-model="editStoreInfo.first_name" :class="{ 'is-invalid': !editStoreInfo.first_name && isDataSubmitted }" type="text" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Last Name')">
            <b-form-input v-model="editStoreInfo.last_name" :class="{ 'is-invalid': !editStoreInfo.last_name && isDataSubmitted }" type="text" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Location')">
            <GmapAutocomplete
              ref="autoCompleteRef"
              :value="editStoreInfo.current_location"
              class="form-control"
              type="text"
              :class="{ 'is-invalid': !editStoreInfo.current_location && !editStoreInfo.id && isDataSubmitted }"
              @place_changed="setPlace"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Email')">
            <b-form-input v-model="editStoreInfo.email" :class="{ 'is-invalid': !editStoreInfo.email && isDataSubmitted }" type="email" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <password-input :input-field="editStoreInfo.password" :label="$t('Enter password')" :listener="'password'" @update-changes="updateChanges" />
        </b-col>
        <b-col cols="12" md="6">
          <password-input :input-field="editStoreInfo.password_confirmation" :label="$t('Enter confirm password')" :listener="'password_confirmation'" @update-changes="updateChanges" />
        </b-col>
        <b-col cols="12" md="6" style="z-index: 999999">
          <b-form-group :label="$t('Phone Number')">
            <VuePhoneNumberInput ref="countryCodeRef" v-model="editStoreInfo.mobile" placeholder="+91 0000000000" @update="onPhoneNumberUpdate" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="no-margin-radio">
          <b-form-group :label="$t('Select Gender')">
            <b-form-radio-group v-model="editStoreInfo.gender" :options="genderOptions" class="demo-inline-spacing mb-1" value-field="value" text-field="text" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Basic Salary')">
            <b-form-input v-model="editStoreInfo.basic_salary" type="number" :step="0.5" min="0" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Start Date')">
            <b-form-datepicker
              v-model="editStoreInfo.start_date"
              locale="en"
            />
          </b-form-group>
        </b-col>
        <div class="col-12 col-md-6">
            <b-form-group 
                id="country_id-group" 
                label="Country"
            >
                <v-select id="country_id" v-model="editStoreInfo.country_id" :reduce="i => i.id" :options="countryOpts" :loading="loadingCountries" @input="onCountryChange" />
            </b-form-group>
        </div>
        <div class="col-12 col-md-6">
            <b-form-group 
                id="state_id-group" 
                label="State"
            >
                <v-select id="state_id" v-model="editStoreInfo.state_id" :reduce="i => i.id" :options="stateOpts" :loading="loadingStates" @input="onStateChange" />
            </b-form-group>
        </div>
        <div class="col-12 col-md-6">
            <b-form-group 
                id="city_id-group" 
                label="City"
            >
                <v-select id="city_id" v-model="editStoreInfo.city_id" :reduce="i => i.id" :options="cityOpts" :loading="loadingCities" />
            </b-form-group>
        </div>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('Picture')">
            <image-input :default-image="editStoreInfo.picture" :class="{ 'is-invalid': !editStoreInfo.picture && isDataSubmitted }" @input-file="inputFile" />
          </b-form-group>
        </b-col>
      </b-row>
      <modal-footer :show-cancel="false" @ok="updateAgents" />
    </b-modal>
    <b-modal v-model="showAgentTimings" :no-close-on-backdrop="true" :title="'Agent Timing'" size="lg" ok-only hide-footer :ok-title="$t('Update Agent Timing')" @ok="updateAgentTiming">
      <agent-timings :agent-timings="agentTimings" />
      <modal-footer :show-cancel="true" @hidden="showAgentTimings = false" @ok="updateAgentTiming" />
    </b-modal>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BFormGroup, BFormInput, BFormRadioGroup, BFormSelect, BModal } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import ModalFooter from '@/@core/components/modal-footer/ModalFooter.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import PasswordInput from '../users/shared/PasswordInput.vue'
import ImageInput from '../users/shared/ImageInput.vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { useLoginUi } from '../auth/useAuth'
import { useStoreUi } from './useStore'
import AgentTimings from '../provider/shared/AgentTimings.vue'
import { useUserUi } from '../users/useUser';
import { dateTimeFormat, isEmpty } from '@/utilities'
export default {
  components: {
    BRow,
    BCol,
    BModal,
    ModalFooter,
    GoodDataTable,
    BCard,
    BFormGroup,
    BFormInput,
    PasswordInput,
    VuePhoneNumberInput,
    BFormRadioGroup,
    BFormSelect,
    ImageInput,
    AgentTimings,
  },
  data() {
    return {
      isDataSubmitted: false,
      genderOptions: [
        { text: 'Male', value: 'MALE', disabled: false },
        { text: 'Female', value: 'FEMALE', disabled: false },
      ],
      agentsColumn: [
        {
          label: 'Image',
          field: 'picture',
          align: 'center',
          preventExport: true,
          useHtml: true,
          excelHtml: props => props.picture,
          renderer: props =>
            `<img src="${window.SuperApp.helper.image(props.picture)}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" style="width: 40px; height: 40px; border-radius: 10%;" />`,
        },
        {
          label: 'First Name',
          field: 'first_name',
        },
        {
          label: 'Last Name',
          field: 'last_name',
        },
        {
          label: 'Wallet Balance',
          field: 'wallet_balance',
          rField: 'wallet_balance_text',
          useRenderer: true,
          useResolver: true,
          renderer: props => `${props.wallet_balance} ${props.currency_symbol}`,
        },
        {
          label: 'Status',
          field: 'activation_status',
          rField: 'active_status_text',
          align: 'center',
          useRenderer: true,
          useResolver: true,
          renderer: props => {
            // eslint-disable-next-line eqeqeq
            if (props.activation_status == 1 && props.status === 'APPROVED') {
              return 'Active'
            }
            return 'Inactive'
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.editAgent(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('status'),
              text: 'Change Status',
              action: props => {
                this.updateAgentsStatus(props)
              },
            },
            {
              isIcon: true,
              // eslint-disable-next-line global-require
              iconHtml: require('@/assets/images/app-icons/Timing.png'),
              text: 'Timings',
              action: props => {
                this.showAgentTimings = true
                this.editTimings(props)
              },
            },
          ],
        },
      ],
      countryOptions: [],
      stateOptions: [],
      cityOptions: [],
      countryList: [],
      walletList: null,
      editStoreInfo: {
        location: '',
      },
      editProviderInfo: {},
      showStoreEdit: false,
      agentTimings: {
        timings: [],
      },
      showAgentTimings: false,
      loadingCountries: false,
      loadingStates: false,
      loadingCities: false,
      countries: [],
      states: [],
      cities: [],
    }
  },
  mounted() {
    this.getCounties();
  },
  computed: {
    countryOpts: {
        get() {
            return this.countries.map(i => ({
                label: i.country_name,
                id: i.id
            }))
        }
    },
    stateOpts: {
        get() {
            return this.states.map(i => ({
                label: i.state_name,
                id: i.id
            }))
        }
    },
    cityOpts: {
        get() {
            return this.cities.map(i => ({
                label: i.city_name,
                id: i.id
            }))
        }
    },
  },
  methods: {
    updateAgentTiming() {
      const formData = new FormData()
      const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
      days.forEach((x, i) => {
        if (this.agentTimings.timings[i]) {
          if (this.agentTimings.timings[i].agent_enabled) {
            formData.append(`day[${x}]`, this.agentTimings.timings[i].day)
          }
          formData.append(`hours_opening[${x}]`, this.agentTimings.timings[i].start_time)
          formData.append(`hours_closing[${x}]`, this.agentTimings.timings[i].end_time)
        }
      })
      formData.append('_method', 'PATCH')
      const { saveStoreTiming } = useStoreUi()
      showLoader()
      saveStoreTiming({
        id: this.agentTimings.agent_id,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Updated successfully')
            this.showAgentTimings = false
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    editTimings(props) {
      const { getStoreTiming } = useStoreUi()
      showLoader()
      getStoreTiming(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            const response = data.responseData
            const dayKeys = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
            this.agentTimings = {
              agent_id: props.id,
              // everyday: response[0] && response[0].day === 'ALL',
              timings: [],
            }
            if (this.agentTimings.everyday) {
              this.agentTimings = {
                ...this.agentTimings,
                timings: [
                  {
                    day: 'ALL',
                    agent_enabled: true,
                    end_time: this.getStoreTIme(response, 'ALL', 'end_time'),
                    start_time: this.getStoreTIme(response, 'ALL', 'start_time'),
                    open_all_time: response && response[0] && response[0].open_all_time === 1,
                  },
                ],
              }
            } else {
              this.agentTimings = {
                ...this.agentTimings,
                timings: dayKeys.map(x => ({
                  day: x,
                  end_time: this.getStoreTIme(response, x, 'end_time'),
                  start_time: this.getStoreTIme(response, x, 'start_time'),
                  agent_enabled: this.isTimeEnabled(response, 'ALL') ? true : this.isTimeEnabled(response, x),
                })),
              }
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getStoreTIme(timings, key, tag) {
      return timings && timings.find(x => x.day === key) ? timings.find(x => x.day === key)[tag] : '00:00'
    },
    isTimeEnabled(timings, key) {
      return timings && Boolean(timings.find(x => x.day === key))
    },
    setPlace(place) {
      const marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.editStoreInfo.current_location = place.formatted_address
      this.editStoreInfo.latitude = marker.lat
      this.editStoreInfo.longitude = marker.lng
    },
    showAddStoreModal() {
      this.showStoreEdit = true
      setTimeout(() => {
        const storeCountryCode = window.SuperApp.getters.userInfo().country_code
        if (storeCountryCode) {
          window.SuperApp.helper.setCountryCode(this.$refs.countryCodeRef, storeCountryCode, '')
        }
      }, 250)
      this.editStoreInfo = {
        id: 0,
        first_name: '',
        country_code: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        gender: 'MALE',
        country_id: '0',
        state_id: '',
        picture: '',
        city_id: '',
        mobile: '',
        latitude: '',
        longitude: '',
      }
      this.isDataSubmitted = false
    },
    inputFile(payload) {
      if (this.editStoreInfo) this.editStoreInfo.picture = payload
    },
    onPhoneNumberUpdate(payload) {
      if (this.editStoreInfo) this.editStoreInfo.mobile = payload.nationalNumber
      if (this.editStoreInfo && payload && payload.countryCallingCode) this.editStoreInfo.country_code = payload.countryCallingCode
    },
    updateChanges(props) {
      console.log(props, 'props')
      this.editStoreInfo[props.key] = props.value
    },
    hideStoreAgents() {
      // this.editStoreInfo = null
      this.showStoreEdit = false
    },
    updateAgentsStatus(props) {
      this.$swal({
        title: this.$t('You want to change the status?'),
        iconHtml: this.$helpers.swalIcon('confirm.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const StoreObj = {
            query: props.id,
            // eslint-disable-next-line eqeqeq
            status: props.activation_status == 1 && props.status == 'APPROVED' ? 'Disable' : 'Enable',
          }
          const { updateAgentStatus } = useStoreUi()
          showLoader()
          updateAgentStatus(StoreObj)
            .then(({ data }) => {
              if (data.statusCode === '200') {
                this.$refs.agentsGrid.loadItems()
                showSuccessNotification(this, data.message)
              } else {
                showDangerNotification(this, data.message)
              }
              hideLoader()
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    editAgent(props) {
      showLoader()
      const self = this
      const { getStoreAgentById } = useStoreUi()
      getStoreAgentById(props.id)
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            const payloadInfo = window.SuperApp.actions.cloneDeep(data.responseData)
            this.editStoreInfo = data.responseData
            self.editStoreInfo.location = ''
            this.showStoreEdit = true
            this.editStoreInfo.picture = window.SuperApp.helper.image(this.editStoreInfo.picture)
            setTimeout(() => {
              const storeCountryCode = payloadInfo.country_code
              if (storeCountryCode) {
                window.SuperApp.helper.setCountryCode(this.$refs.countryCodeRef, storeCountryCode, this.editStoreInfo.mobile)
              }
            }, 500)
            if (this.editStoreInfo.latitude && this.editStoreInfo.longitude) {
              setTimeout(() => {
                window.SuperApp.helper.getFormattedAddress(`${this.editStoreInfo.latitude},${this.editStoreInfo.longitude}`).then(res => {
                  if (self.$refs.autoCompleteRef && self.$refs.autoCompleteRef.$el) {
                    self.$refs.autoCompleteRef.$el.value = ''
                  }
                  self.editStoreInfo.location = window.SuperApp.helper.parsedAddress(res)
                  if (self.$refs.autoCompleteRef && self.$refs.autoCompleteRef.$el) {
                    self.$refs.autoCompleteRef.$el.value = self.editStoreInfo.location
                  }
                })
              }, 500)
            }
            this.onCountryChange(this.editStoreInfo.country_id)
            this.onStateChange(this.editStoreInfo.state_id)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getAgentForm() {
      const formData = new FormData()
      const formArray = ['first_name', 'latitude', 'current_location', 'longitude', 'id', 'country_code', 'last_name', 'email', 'basic_salary', 'start_date', 'gender', 'country_id', 'city_id', 'state_id']
      formArray.forEach(item => {
        if(item == 'start_date'){
          formData.append(item, dateTimeFormat(this.editStoreInfo[item], 'YYYY-MM-DD'))
        }else{
          formData.append(item, this.editStoreInfo[item])
        }
      })
      if (this.editStoreInfo.password) {
        formData.append('password', this.editStoreInfo.password)
      }
      if (this.editStoreInfo.password_confirmation) {
        formData.append('password_confirmation', this.editStoreInfo.password_confirmation)
      }
      if (this.editStoreInfo.mobile) formData.append('mobile', this.editStoreInfo.mobile.replace(/[^0-9]/g, ''))
      if (this.editStoreInfo.picture && window.SuperApp.helper.withBaseUrl(this.editStoreInfo.picture)) formData.append('picture', this.editStoreInfo.picture)
      formData.append('parent_type', 'SHOP')
      return formData
    },
    deleteAgents(props) {
      this.$swal({
        title: this.$t('You want to delete this agent?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const { deleteAgentsProfile } = useStoreUi()
          showLoader()
          deleteAgentsProfile(props.id)
            .then(({ data }) => {
              if (data.statusCode === '200') {
                this.$refs.agentsGrid.loadItems()
                showSuccessNotification(this, data.message)
              } else {
                showDangerNotification(this, data.message)
              }
              hideLoader()
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    addAgents() {
      const formData = this.getAgentForm()
      formData.delete('id')
      this.showStoreEdit = false
      const { addShopAgent } = useStoreUi()
      showLoader()
      addShopAgent(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.agentsGrid.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
            this.showStoreEdit = true
          }
          hideLoader()
        })
        .catch(error => {
          this.showStoreEdit = true
          showErrorNotification(this, error)
        })
    },
    updateAgents() {
      this.isDataSubmitted = true
      setTimeout(() => {
        if (window.SuperApp.validations.anyErrorInModal()) {
          return
        }
        this.showStoreEdit = false
        this.isDataSubmitted = false
        if (!this.editStoreInfo.id) {
          this.addAgents()
          return
        }
        const formData = this.getAgentForm()
        formData.append('_method', 'PATCH')
        const { updateShopAgent } = useStoreUi()
        showLoader()
        updateShopAgent({
          query: this.editStoreInfo.id,
          data: formData,
        })
          .then(({ data }) => {
            if (data.statusCode === '200') {
              this.$refs.agentsGrid.loadItems()
              showSuccessNotification(this, 'Agents updated successfully')
            } else {
              showDangerNotification(this, data.message)
            }
            hideLoader()
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      })
    },
    async getCounties() {
      this.countries = []
      this.loadingCountries = true
      this.loadingStates = true
      this.loadingCities = true
      const { countryList } = useUserUi()
      await countryList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.countries = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
      this.loadingCountries = false
      this.loadingStates = false
      this.loadingCities = false
    },
    async onCountryChange(country_id) {
      if (isEmpty(this.editStoreInfo.country_id)) return
      this.states = []
      this.cities = []
      if(!country_id){
        this.editStoreInfo.state_id = null
        this.editStoreInfo.city_id = null
      }
      this.loadingStates = true
      this.loadingCities = true
      const { getStateByCountry } = useUserUi()
      await getStateByCountry(this.editStoreInfo.country_id ?? country_id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.states = data.responseData
          } else {
            showErrorNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
      this.loadingStates = false
      this.loadingCities = false
    },
    async onStateChange(state_id) {
      if (isEmpty(this.editStoreInfo.country_id)) return
      if (isEmpty(this.editStoreInfo.state_id)) return
      if(!state_id){
        this.editStoreInfo.city_id = null
      }
      this.cities = []
      this.loadingCities = true
      const { getCityByStateCountry } = useUserUi()
      await getCityByStateCountry(`country_id=${this.editStoreInfo.country_id}&state_id=${this.editStoreInfo.state_id ?? state_id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.cities = data.responseData
          } else {
            showErrorNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })

      this.loadingCities = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

[dir] .vs__dropdown-toggle {
  border: 1px solid #d8d6de;
}
</style>

<style>
@media only screen and (max-width: 767px) {
  .shop-agents .grid-main-action-button {
    display: block !important;
    text-align: right;
    margin-bottom: 10px;
  }
}
</style>
